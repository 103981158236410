//直播间管理接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-9 16:15:50
 */

//直播间管理分页查询
export function liveAdminList(parameter){
    return axios({
        url: '/liveBroadcast/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//直播间管理详情
export function liveAdminDetail(parameter){
    return axios({
        url: '/liveBroadcast/detail',
        method: 'post',
        params: parameter 
    })
}
//直播间管理终止
export function liveAdminEnd(parameter){
    return axios({
        url: '/liveBroadcast/termination',
        method: 'post',
        data: parameter
    })
}


//直播间商品分页查询
export function liveCommodityList(parameter){
    return axios({
        url: '/liveBroadcast/goodsPage',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}

//直播间商品添加
export function liveCommodityAdd(parameter){
    return axios({
        url: '/liveBroadcast/goodsIsPutaway', 
        method: 'post',
        data: parameter
    })
}
//直播间商品删除
export function liveCommodityDelete(parameter){
    return axios({
        url: '/liveBroadcast/goodsIsPutawayDelete',
        method: 'post',
        data: parameter
    })
}


//直播间礼物明细
export function liveGiftDetail(parameter){
    return axios({
        url: '/sendGift/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//查询礼物数量和产币
export function liveGiftCon(parameter){
    return axios({
        url: '/sendGift/dataStatistics',
        method: 'post',
        data: parameter
    })
}